import "./About.css";
import Rectangle from "../../components/Rectangle/Rectangle";
import Lines from "../../components/Lines/Lines";
import Dot from "../../components/Dot/Dot";
import List from "../../components/List/List";

function About(props) {
  const education = [
    {
      startDate: "JULY 2021",
      endDate: "AUGUST 2022",
      content: "Google UX Design Professional Certificate",
    },
    {
      startDate: "JULY 2022",
      endDate: "ongoing",
      content: "Meta Front-End Developer Professional Certificate",
    },
    {
      startDate: "SEPTEMBER 2007",
      endDate: "MAY 2010",
      content:
        "BA of Arts in Film Production (Production Design) @ University of Gloucestershire, England",
    },
    {
      startDate: "AUGUST 2006",
      endDate: "DECEMBER 2006",
      content: "Theatre and Fine Art @ Ungdomshojskole Ved Ribe, Denmark",
    },
  ];

  const skills = [
    "UX/UI",
    "Design thinking",
    "Competitive analysis",
    "User research",
    "User journey",
    "Design system",
    "Information architecture",
    "Accessibility",
    "HTML",
    "CSS"
  ];

  const tools = [
    "Figma",
    "Adobe XD",
    "Adobe After Effects",
    "Adobe Illustrator",
    "Adone InDesign",
    "Visual Studio Code",
  ];

  return (
    <div className="about-container">
      <div className="about-illustration-container">
        <Lines />
        <Rectangle color="black" width="17px" height="191px" />
      </div>
      <div className="about-text-container">
        <Lines />
        <div className="about-text-illustration">
          <div className="about-text-exclamation">
            <Rectangle height="144px" width="12px" color="black" />
            <Dot color="gold" />
          </div>
          <div className="about-text-bio-container">
            <Rectangle height="261px" width="52px" color="gold" />
            <Rectangle height="261px" width="17px" color="black" />
            <article className="about-text-bio">
              <p>
                Design has always been my passion. From my early days as a set
                designer for film in university, to creating stage designs for
                theatre, I have always been drawn to the world of design.
              </p>
              <p>
                When I discovered UX design, I knew it was the perfect fit for
                me. I was looking for a career where I could use my creativity
                to optimize experiences for users. My interest in design and
                human behavior led me to fall in love with UX design.
              </p>
              <p>
                I am known for my keen attention to detail and my deep love for
                color psychology. UX design is an endlessly fascinating field
                with so much to learn, and I am excited to continue growing my
                knowledge and skills in creating intuitive and user-friendly
                designs.
              </p>
            </article>
          </div>
        </div>
      </div>
      <div className="about-end-container">
        <Lines />
        <p className="abut-end-p">
          HEY! I AM EMILIA, BAY AREA, CA BASED POLISH DESIGNER
        </p>
      </div>
      <div className="about-lists">
        <List items={education} title="Education" complex />
        <List items={skills} title="Skills" />
        <List items={tools} title="Tools" />
      </div>
      <div className="about-illustration-end">
        <Rectangle width="52px" height="102px" color="black" />
      </div>
    </div>
  );
}

export default About;
