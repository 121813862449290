import "./WorkBox.css";
import { Link } from "react-router-dom";

function WorkBox(props) {
  return (
    <Link className="work-box-container" style={{ backgroundImage: `url(${props.img})`, backgroundColor: "white" }} to={`/${props.url}`}>
      <h3 className="work-box-title">{props.title}</h3>
      <p className="work-box-description">{props.description}</p>
      <span href="" className="work-box-learn">Learn more</span>
    </Link>
  );
}

export default WorkBox;
