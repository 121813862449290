import "./VisualDesignPortfolio.css";
import { useState } from "react";
import Dot from "../../components/Dot/Dot";
import ExternalLinkWrapper from "../../components/ExternalLinkWrapper/ExternalLinkWrapper";

function VisualDesignPortfolio(props) {
  const [complexType, setComplexType] = useState(props.complexType);

  return (
    <div className="visual-design-container">
      <div className="visual-design-content">
        <h1 className="common-title">
          Visual Design
          <Dot color="black" />{" "}
        </h1>
        <div className="visual-design-inspired-container">
          <h2 className="common-subtitle">What inspired me</h2>
          <p className="common-description visual-design-inspired-description">
            {props.description}
          </p>
          {props.img ? (
            <img src={props.img} className="visual-design-inspired-img" />
          ) : null}
        </div>
        <div className="visual-design-colors-primary">
          <h2 className="common-subtitle">COLOR PALLETTE</h2>
          <ul className="visual-design-colors-primary-list">
            {props.colors.map((color, i) => (
              <li
                style={{
                  backgroundColor: color.color,
                  color: color.text,
                  height: `${300 - i * 50}px`,
                  width: `${300 - i * 50}px`,
                }}
              >
                {color.color}
              </li>
            ))}
          </ul>
          {props.complexColors ? (
            <div className="visual-design-complex-colors-container">
              <h3 className="visual-design-complex-colors-title">Emotions</h3>
              <ul className="visual-design-complex-colors-list">
                {props.complexColors.map((color, i) => (
                  <>
                    <li className="visual-design-complex-colors-item">
                      <h4 className="visual-design-complex-colors-h4">
                        {color.label}
                      </h4>
                      <ul className="visual-design-complex-colors-pair-list">
                        <li
                          className="visual-design-complex-colors-pair-item"
                          style={{
                            backgroundColor: color.color1.color,
                            color: color.color1.text,
                          }}
                        >
                          {color.color1.color}
                        </li>
                        <li
                          className="visual-design-complex-colors-pair-item"
                          style={{
                            backgroundColor: color.color2.color,
                            color: color.color2.text,
                          }}
                        >
                          {color.color2.color}
                        </li>
                      </ul>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div className="visual-design-type">
          <h2 className="common-subtitle">Typography</h2>
          <div
            className={`${
              props.complexType
                ? "visual-design-type-complex-container"
                : "visual-design-type-container"
            }`}
          >
            {props.type1 ? (
              <>
                <div className="visual-design-type-content">
                  <h3 className="visual-design-type-h3">Font Families</h3>
                  <img src={props.type1} className="visual-design-type-img" />
                </div>
                <div>
                  <h3 className="visual-design-type-h3">Headers</h3>
                  <img src={props.type2} className="visual-design-type-img" />
                </div>
              </>
            ) : complexType[0] ? (
              complexType.map((type, i) => (
                <div className="visual-design-complex-container">
                  <h2 className="visual-design-type-h2">{type.label}</h2>
                  {type.fonts.map((font, i) => (
                    <div className="visual-design-type-content">
                      <h3 className="visual-design-type-h3">{`${
                        i % 2 != 0 ? "Font Families" : "Headers"
                      }`}</h3>
                      <img className="visual-design-type-img" src={font} />
                    </div>
                  ))}
                </div>
              ))
            ) : null}
          </div>
        </div>
        {props.url ? (
          <ExternalLinkWrapper
            url={props.url}
            label={props.label}
            color={props.color}
          />
        ) : null}
      </div>
    </div>
  );
}

export default VisualDesignPortfolio;
