import "./ExternalLinkWrapper.css";
import ExternalLink from "../ExternalLink/ExternalLink";

function ExternalLinkWrapper(props) {
  return (
    <div className="externallink-wrapper-container">
      <ExternalLink url={props.url} color={props.color} label={props.label} />
    </div>
  );
}

export default ExternalLinkWrapper;
