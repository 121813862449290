import "./FloristMobileApp.css";
import Nav from "../../containers/Nav/Nav";
import Footer from "../../containers/Footer/Footer";
import Button from "../../components/Button/Button";
import NarrowContent from "../../components/NarrowContent/NarrowContent";
import MiniStepper from "../../components/MiniStepper/MiniStepper";
import BeforeAfter from "../../components/BeforeAfter/BeforeAfter";
import HeroPortfolio from "../../containers/HeroPortfolio/HeroPortfolio";
import ExternalLinkWrapper from "../../components/ExternalLinkWrapper/ExternalLinkWrapper";
import OverviewPortfolio from "../../containers/OverviewPortfolio/OverviewPortfolio";
import ResearchPortfolio from "../../containers/ResearchPortfolio/ResearchPortfolio";
import MocksPortfolio from "../../containers/MocksPortfolio/MocksPortfolio";
import AccessibilityPortfolio from "../../containers/AccessibilityPortfolio/AccessibilityPortfolio";
import VisualDesignPortfolio from "../../containers/VisualDesignPortfolio/VisualDesignPortfolio";
import FinalDesignPortfolio from "../../containers/FinalDesignPortfolio/FinalDesignPortfolio";
import ViewOtherProjects from "../../containers/ViewOtherProjects/ViewOtherProjects";
import Flo_1 from "../../assets/img/flo/flo_1.png";
import Flo_persona1 from "../../assets/img/flo/flo_persona1.png";
import Flo_wire_1 from "../../assets/img/flo/flo_wire_1.png";
import Flo_wire_2 from "../../assets/img/flo/flo_wire_2.png";
import Flo_wire_3 from "../../assets/img/flo/flo_wire_3.png";
import Flo_before from "../../assets/img/flo/flo_wire_4.png";
import Flo_after from "../../assets/img/flo/flo_wire_5.png";
import Flo_mock_1 from "../../assets/img/flo/flo_mock_1.png";
import Flo_mock_2 from "../../assets/img/flo/flo_mock_2.png";
import Flo_mock_3 from "../../assets/img/flo/flo_mock_3.png";
import Flo_mock_4 from "../../assets/img/flo/flo_mock_4.png";
import Flo_mock_5 from "../../assets/img/flo/flo_mock_5.png";
import Flo_access_1 from "../../assets/img/flo/flo_nav_before.png";
import Flo_access_2 from "../../assets/img/flo/flo_nav_after.png";
import Flo_access_3 from "../../assets/img/flo/flo_searchbar.png";
import flo_type_1 from "../../assets/img/flo/flo_type_1.png";
import flo_type_2 from "../../assets/img/flo/flo_type_2.png";
import flo_flow from "../../assets/img/flo/flo_flow.png";
import flo_flow_mobile from "../../assets/img/flo/flo_flow_mobile.png";

function FloristMobileApp() {
  const url =
    "https://www.figma.com/proto/kQDqZFcMYBWCwPsgFyLlTL/FLO?node-id=33%3A6&viewport=875%2C609%2C0.17&scaling=min-zoom&starting-point-node-id=33%3A7";
  const stickerSheetUrl = "https://drive.google.com/file/d/1sOdNrLklpp4-tzd9WNelv2gwCB0l1jU4/view?usp=sharing";

  return (
    <>
      <Nav hideElements pink />
      <div className="florist-container commmon-portfolio-container">
        <HeroPortfolio
          title="Flo — The Florist Mobile App"
          description="Mobile app design and product design for hypothetical trendy florist shop.
                "
          url={url}
          label="see prototype"
          img={Flo_1}
          beforeHeight="500"
          color="pink"
          imgKind="mobile"
        />
        <OverviewPortfolio
          title="overview"
          role="ux design lead"
          duration="november 2021/may 2022"
          client="grow with google, ux design course"
          product="FLO is a hypothetical Bay Area, CA based florist shop specializing in modern-looking bouquets. Their highly aesthetic flower bouquets follow latest trends and target young professionals, who often purchase flower bouquets as gifts."
          goal="Design an app for a florist shop that stands out on a busy market. Let customers buy bouquets based on the personality of the person they are gifting + let customers make their own bouquets."
          challenge="Busy young professionals want an easy way to buy trendy personalized bouquets online for their loved ones. 
                    The product challenge is creating an app flow that allows easy filtering and flower selection."
        />
        <ResearchPortfolio
          process={[
            "inteviews",
            "personas",
            "user stories",
            "user journey map",
            "pain points",
          ]}
          processDescription="I conducted interviews and created empathy maps to understand the users I’m designing for and their needs. A primary user group identified through research
                    was young professional adults who relocated for work and want to send bouquets to their family and friends."
          problemStatement="Dan is a young professional who needs to order flower bouquets online because he can’t always attend the celebrations in person."
          personaImage={Flo_persona1}
          personaQuote="“I want to stay connected to my close ones and send them gifts for the celebrations I can’t attend.”"
          name="Dan"
          age="25"
          education="Master degree"
          hometown="Bend, Oregon"
          family="single"
          occupation="developer"
          goals={[
            "Send flowers as gifts to their relatives",
            "Have option to personalize bouquets to add a unique touch",
          ]}
          frustrations={[
            "Most flower services are old-fashion and don’t have many exciting choices",
            "All bouquets come premade, no room for customization",
          ]}
          painpointsDescription="Through interviews, I found out, that people want to express more and show the connection to the person they are gifting. Hence the idea is to describe the flower's traits and let people make their bouquets based on the personality of the person they are gifting."
          painpoints={[
            [
              "time",
              "Busy professionals don’t always have time to attend celebrations due to time and location.",
            ],
            [
              "look",
              "Young professionals want to buy trendy looking bouquets.",
            ],
            [
              "peronality",
              "Customers want to express more with their bouquet choices.",
            ],
            [
              "feature",
              "Letting customers create their own bouquets in few quick and easy steps.",
            ],
          ]}
          color="pink"
        />
        {/* Low fidelity section */}
        <MocksPortfolio
          processTitle="low fidelity"
          process={[
            "paper wireframes",
            "digital wireframes",
            "low-fidelity prototype",
            "usability study",
          ]}
        >
          <div className="common-mocks-container">
            <h2 className="common-subtitle">Wireframes</h2>
            <div className="common-content">
              <NarrowContent
                title="Pre-made vs. Customized"
                description="I wanted users to be able to choose between premade bouquets and customized ones. For those in a hurry they can quickly select premade options from the homepage."
                image={Flo_wire_1}
              />
              <NarrowContent
                title="MAKE YOUR OWN BOUQUET"
                description="Basing on the findings from the user research I created a “Make Your Own Bouquet” feature, that lets users customize their bouquets."
                image={Flo_wire_2}
              />
              <NarrowContent
                title="Filters"
                description="Filtering by flower personality traits can help users create a bouquet based on the personality of the person they are gifting. This feature creates a unique buying experience."
                image={Flo_wire_3}
              />
            </div>
            <ExternalLinkWrapper
              label="Low Fidelity Prototype"
              url="https://www.figma.com/proto/HMNRgcs437q0o7OvUS3hXH/FLO-wireframes?node-id=0%3A1&viewport=758%2C1121%2C0.23&scaling=scale-down&starting-point-node-id=14%3A246"
              color="pink"
            />
          </div>
          <div className="common-before-after-container">
            <h2 className="common-subtitle">1st Usability Study Findings</h2>
            <MiniStepper
              color="pink"
              steps={[
                "Users like to have an option to customize their bouquets",
                "They need more visual indicators about the “add message” step",
                "Users like that they can buy bouquets based on the personality traits of the person they are gifting ",
              ]}
            />
            <BeforeAfter beforeImg={Flo_before} afterImg={Flo_after} />
          </div>
        </MocksPortfolio>
        {/* High fidelity section */}
        <MocksPortfolio
          processTitle="high fidelity"
          processDescription="Going to high-fidelity was an amazing learning oportunity, where I could deepen my existing knowledge on typography and colors. I wanted to make sure I spend a lot of time studying different typefaces to select one that is easy to read/ scan and aligns with the projects aesthetics. I was aiming to create an airy, modern design that excites the users."
          process={["mockups", "high-fidelity prototype", "usability study"]}
          pink
        >
          <div className="common-mocks-container">
            <h2 className="common-subtitle">Mockups</h2>
            <div className="common-content">
              <NarrowContent
                title="Filters"
                description="After the 1st usability study it became clear to me that I need to change my approach to filters. 
                I designed a pop-up window that let’s users easily select filtering cathegories."
                image={Flo_mock_1}
              />
              <NarrowContent
                title="Easy Browsing"
                description="Large flower pictures with short description focusing on their personality lets users easily browse and select the flowers to create their unique bouquet."
                image={Flo_mock_2}
              />
              <NarrowContent
                title="Compatible flowers"
                description="To make it easy for people to create their own bouquets, I’ve added a section that suggests compatible flower options that links directly to their pages, where users can select them."
                image={Flo_mock_3}
              />
            </div>
            <ExternalLinkWrapper
              label="High Fidelity Prototype"
              url="https://www.figma.com/proto/kQDqZFcMYBWCwPsgFyLlTL/FLO?node-id=33%3A6&viewport=629%2C637%2C0.17&scaling=min-zoom&starting-point-node-id=33%3A7"
              color="pink"
            />
            <div className="common-before-after-container">
              <h2 className="common-subtitle">2nd USABILITY STUDY FINDINGS</h2>
              <MiniStepper
                color="pink"
                steps={[
                  "Users want to see the bouquet that they are making",
                  "They need more visual indicators about the “add message” step",
                  "Users like that they can buy bouquets based on the personality traits of the person they are gifting ",
                ]}
              />
              <BeforeAfter beforeImg={Flo_mock_4} afterImg={Flo_mock_5} />
            </div>
          </div>
        </MocksPortfolio>
        <AccessibilityPortfolio color="pink" title="accessibility">
          {/* Custom content for the accessibility section */}
          <div className="common-content">
            <div className="flo-accessibility-container">
              <ol className="mini-stepper-list">
                <li className="mini-stepper-item">
                  <div className="flo-accessibility-mini-step">
                    <span className="mini-stepper-numbers mini-stepper-numbers--pink">
                      1
                    </span>
                    <p className="mini-stepper-description">
                      After running color contrast check, I changed font and
                      background color to meet the WCAG requirements. Before-
                      2.89 contrast ratio, after- 4.71 contrast ratio.
                    </p>
                  </div>
                  <div className="flo-accessibility-wrapper flo-accessibility-first-img">
                    <div className="flo-accessibility-img flo-accessibility-first-img-wrapper">
                      <h3 className="flo-accessibility-header">Before</h3>
                      <img src={Flo_access_1} alt="before" />
                    </div>
                    <div className="flo-accessibility-wrapper flo-accessibility-img flo-accessibility-first-img-wrapper">
                      <h3 className="flo-accessibility-header">After</h3>
                      <img src={Flo_access_2} alt="after" />
                    </div>
                  </div>
                </li>
                <li className="mini-stepper-item">
                  <div className="flo-accessibility-mini-step">
                    <span className="mini-stepper-numbers mini-stepper-numbers--pink">
                      2
                    </span>
                    <p className="mini-stepper-description">
                      I added a voice option to search to support users with
                      dexterity challenges.
                    </p>
                  </div>
                  <div className="flo-accessibility-img flo-accessibility-second-img">
                    <div className="flo-accessibility-wrapper flo-accessibility-img flo-accessibility-second-img-wrapper">
                      <img src={Flo_access_3} alt="before" />
                    </div>
                  </div>
                </li>
                <li className="mini-stepper-item">
                  <div className="flo-accessibility-mini-step">
                    <span className="mini-stepper-numbers mini-stepper-numbers--pink">
                      3
                    </span>
                    <p className="mini-stepper-description">
                      Large flower and bouquet pictures are helping neurodiverse
                      users navigate through the app.
                    </p>
                  </div>
                  <div className="flo-accessibility-img flo-accessibility-third-img">
                    <div className="flo-accessibility-wrapper flo-accessibility-img flo-accessibility-third-img-wrapper">
                      <img src={Flo_access_3} alt="before" />
                    </div>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </AccessibilityPortfolio>
        <VisualDesignPortfolio
          description="My inspiration for the color pallette were pictures of flowers. I wanted to create something modern yet elegant and light. I used Coolors to pick colors from the hero image I choose for the homepage.
            Typography was also crucial to ensure that the text is easy to read and aligns with the modern style of the app.  
            For this project I chose geometric sans serif- Popppins because of its round features and modern character. 
            I wanted the visual style to appeal to the target users of the app and provide them with highly aesthetic 
            experience for buying flower bouquets."
          colors={[
            { color: "#FBF5F7", text: "#304540" },
            { color: "#CE8296", text: "#FBF9F7" },
            { color: "#E4C5CD", text: "#241F1D" },
            { color: "#304540", text: "#FBF9F7" },
            { color: "#CDD0D3", text: "#241F1D" },
          ]}
          type1={flo_type_1}
          type2={flo_type_2}
          color="pink"
          label="sticker sheet"
          url={stickerSheetUrl}
        />
        <FinalDesignPortfolio
          description="Mobile app for flow being my first UX design project I learned all the stages of design process
          from start to finish. I found the empathize stage very important where I understood the user’s 
          needs and how I can reflect on that in my mobile app project. 
          
          This project was also a huge lesson on color theory. Color psychology is one of my favourite areas
          in design and I spent a lot of time researching how colors affect human behavior. I wanted to created 
          a pallette that is aesthatical, appealing to user and most imporantly accessible."
          img={flo_flow}
          imgMobile={flo_flow_mobile}
        />
        <ViewOtherProjects />
        <Footer />
      </div>
    </>
  );
}

export default FloristMobileApp;
