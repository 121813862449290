import "./ExternalLink.css";
import OpenLink from "../../assets/vectors/open_link.svg";

function ExternalLink(props) {
  const color = props.color || "blue";
  return (
    <span className="externallink-container">
      <a
        href={props.url}
        className={`externallink ${`externallink--${color}`}`}
        target="_blank"
      >
        {props.label}
      </a>
      <span className="externallink-icn-container">
        <img
          src={OpenLink}
          className={`externallink-icn externallink-icn--${color}`}
        />
      </span>
    </span>
  );
}

export default ExternalLink;
