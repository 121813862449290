import "./NavLink.css";

function CustomLink({ children, to, active, ...props }) {
  return (
    <li className="navlink-container">
      <span
        className={active ? "active" : ""}
        {...props}
      >
        {children}
      </span>
    </li>
  );
}

export default CustomLink;
