import logo from "../../assets/vectors/logo.svg";
import "./HomePage.css";
import Nav from "../../containers/Nav/Nav";
import Hero from "../../containers/Hero/Hero";
import SectionHeader from "../../containers/SectionHeader/SectionHeader";
import Work from "../../containers/Work/Work";
import About from "../../containers/About/About";
import Contact from "../../containers/Contact/Contact";
import Footer from "../../containers/Footer/Footer";

function HomePage() {
  return (
    <div className="homepage-container" id="home">
        <Nav />
      <div className="homepage-top-container">
        <Hero />
      </div>
      <section id="work">
        <SectionHeader title="Selected work" color="mint" />
        <Work />
      </section>
      <section id="about">
        <SectionHeader title="About me" color="gold" lines />
        <About />
      </section>
      <section id="contact">
        <SectionHeader title="Contact me" color="mint" lines />
      </section>
      <section>
        <Contact />
      </section>
      <Footer />
    </div>
  );
}

export default HomePage;
