import "./Hero.css";
import Lines from "../../components/Lines/Lines";
import Dot from "../../components/Dot/Dot";
import Rectangle from "../../components/Rectangle/Rectangle";

function Hero(props) {
  /* Hero is divided into multiple horizontal blocks
   ** to help with drawing all the illustrations
   */
  return (
    <section className="hero-container">
      {/* Top part of the hero. Illustrations only */}
      <div className="hero-lines-container-top">
        <Lines />
        <div className="hero-lines-rectangles-small">
          <Rectangle width="26px" color="black" />
          <Rectangle width="26px" height="152px" color="mint" />
        </div>
        <div className="hero-lines-rectangles-big">
          <Rectangle width="52px" height="337px" color="black" />
          <Rectangle width="52px" height="100%" color="mint" />
        </div>
        <div className="hero-lines-rectangle-single">
          <Rectangle width="12px" height="100px" color="black" />
        </div>
      </div>
      {/* Second part of the hero. Main copy */}
      <div className="hero-copy-container">
        <h1 className="hero-copy-title">UX/UI DESIGN</h1>
        <div className="hero-copy-illustration">
          <Rectangle width="12px" height="45px" color="black" />
          <Dot color="mint" />
          <Rectangle width="12px" height="45px" color="black" />
        </div>
        <span className="hero-copy-name">EMILIA WYSOCKA-TREDER</span>
      </div>
      {/* Third part of the hero. Secondary copy */}
      <div className="hero-tagline-container">
        <div className="hero-tagline-illustrations">
          <Lines />
          <div className="hero-tagline-illustrations-two">
            <Rectangle width="52px" height="196px" color="black" />
            <Rectangle width="52px" height="152px" color="mint" />
          </div>
          <div className="hero-tagline-illustrations-three">
            <Rectangle width="12px" height="106px" color="black" />
          </div>
        </div>
        <h2 className="hero-tagline-headline">
          MINIMALISTIC, TIMELESS & INTUITIVE DESIGN
        </h2>
      </div>
      {/* Fourth part of the hero. Illustrations only. */}
      <div className="hero-end-container">
        <Lines />
        <Rectangle width="52px" height="196px" color="black" />
      </div>
    </section>
  );
}

export default Hero;
