import "./HeroPortfolio.css";
import ExternalLink from "../../components/ExternalLink/ExternalLink";
import OpenLink from "../../assets/vectors/open_link.svg";

function HeroPortfolio(props) {
  // switch based on prop color for colors of links
  let color = "pink";
  switch (props.color) {
    case "pink":
      color = "pink";
      break;
    case "red":
      color = "red";
      break;
    case "red-dark":
      color = "red-dark";
      break;
    case "blue":
      color = "blue";
      break;
  }

  return (
    <div className="hero-portfolio-container">
      <div className="hero-portfolio-content">
        <h1 className="hero-portfolio-title">{props.title}</h1>
        <p className="hero-portfolio-description">{props.description}</p>
        <ExternalLink url={props.url} label={props.label} color={color} />
        <div className="hero-portfolio-image-container">
          <img
            src={props.img}
            alt={props.title}
            className={`hero-portfolio-image--${props.imgKind}`}
          />
        </div>
      </div>
    </div>
  );
}

export default HeroPortfolio;
