import "./MiniStepper.css";

function MiniStepper(props) {
    const steps = props.steps;
    const color = props.color;

    return (
        <ol className="mini-stepper-list">
            {steps.map((item, index) => (
                <li className="mini-stepper-item" key={`ministepper${index}`}>
                    <span className={`mini-stepper-numbers mini-stepper-numbers--${color}`}>{index + 1}</span>
                    <p className="mini-stepper-description">
                        {item}
                    </p>
                </li>
            ))}
        </ol>
    )
}

export default MiniStepper;