import { Link } from "react-router-dom";
import "./Logo.css";
import LogoVector from "../../assets/vectors/logo.svg";

function Logo(props) {
  return (
    <header>
      <Link to="/">
        <h1 className="logo-container" {...props}>
          <img src={LogoVector} alt="Emilia Wysocka-Treder UX Design logo" className="logo-img" />
        </h1>
      </Link>
    </header>
  );
}

export default Logo;
