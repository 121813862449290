import "./WideTwoColumn.css";
import WideContent from "../WideContent/WideContent";

function WideTwoColumn(props) {
    const titleLeft = props.titleLeft;
    const imgLeft = props.imgLeft;
    const textLeft = props.textLeft;
    const titleRight = props.titleRight;
    const imgRight = props.imgRight;
    const textRight = props.textRight;

  return (
    <div className="wide-two-column-container">
      <WideContent title={titleLeft} img={imgLeft} text={textLeft} />
      <WideContent title={titleRight} img={imgRight} text={textRight} />
    </div>
  );
}

export default WideTwoColumn;
