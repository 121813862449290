import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./common/tokens.css";
import "./common/common.css";
import "yet-another-react-lightbox/styles.css";
import HomePage from "./pages/homepage/HomePage";
import FloristMobileApp from "./pages/FloristMobileApp/FloristMobileApp";
import BoxOfGoods from "./pages/BoxOfGoods/BoxOfGoods";
import Emos from "./pages/Emos/Emos";
import TrustedTwinWebsite from "./pages/TrustedTwinWebsite/TrustedTwinWebsite";
import TrustedTwinAcademy from "./pages/TrustedTwinAcademy/TrustedTwinAcademy";
import reportWebVitals from "./reportWebVitals";

/* Router responsible for all the linking */
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  scrollRestoration,
  Switch
} from "react-router-dom";

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log(pathname)
    if (pathname !== "/work") {
      const canControlScrollRestoration = 'scrollRestoration' in window.history
      if (canControlScrollRestoration) {
        window.history.scrollRestoration = 'manual';
      }

      window.scrollTo(0, 0);
    }
    else {
      console.log("TIME TO SCROLL!!!")
    }
  }, [pathname]);

  return children;
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/work" element={<HomePage />} hash="#work" />
        <Route path="/trusted-twin-website" element={<TrustedTwinWebsite />} />
        <Route path="/trusted-twin-academy" element={<TrustedTwinAcademy />} />
        <Route path="/florist-mobile-app-ux" element={<FloristMobileApp />} />
        <Route path="/box-of-goods" element={<BoxOfGoods />} />
        <Route path="/emos" element={<Emos />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


/* TODO LIST
** 1. Add button kind link and enable links to prototypes
** 2. Fix footer (Safari and portfolio pages)
** 3. Test on safari and firefox
**
*/