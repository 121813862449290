import "./MocksPortfolio.css";
import Dot from "../../components/Dot/Dot";
import BigStepper from "../../components/BigStepper/BigStepper";

function MocksPortfolio(props) {
    return (
        <div className={`lofi-portfolio-container ${props.pink ? "mocks-portfolio--pink" : ""}`}>
            <div className="lofi-portfolio-content">
                {/* Classes borrowed from other containers
                ** TODO move shared pieces to a separate component
                */}
                <BigStepper 
                    title={props.processTitle}
                    subtitle="The Process"
                    steps={props.process}
                    description={props.processDescription}
                />
                {props.children}
            </div>
        </div>
    )
}

export default MocksPortfolio;