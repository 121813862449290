/* Assets */
import Box from "../assets/img/box/box_1.png";
import Emos from "../assets/img/emos/emos_1.png";
import Flo from "../assets/img/flo/flo_1.png";
import TrustedTwin from "../assets/img/tt_website_redesign/tt_website_redesign1.png";
import TrustedTwinAcademy from "../assets/img/tt_academy/tt_academy1.png";

export const projects = [
  {
    title: "Trusted Twin — Website",
    description: "Responsive website redesign for data sharing cloud digital twin start-up based in Poland.",
    img: TrustedTwin,
    url: "trusted-twin-website"
  },
  {
    title: "Trusted Twin — Academy",
    description: "Website design for educational service for a digital twin start-up.",
    img: TrustedTwinAcademy,
    url: "trusted-twin-academy"
  },
  {
    title: "Box of Goods",
    description: "Responsive website for Ukrainian Aid Foundation",
    img: Box,
    url: "box-of-goods"
  },
  {
    title: "Emos",
    description:
      "Mobile app and responsive website for a game that teaches kids about emotions",
    img: Emos,
    url: "emos"
  },
  {
    title: "Flo",
    description: "Mobile app for a modern florist",
    img: Flo,
    url: "florist-mobile-app-ux",
  }
]