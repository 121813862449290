import "./Lines.css";

function Lines(props) {
  return (
    /*<ul className="lines-container">
      <li>test</li>
      <li />
      <li />
      <li />
      <li />
    </ul>*/
    <div className="lines-container">
      <div className="line" />
      <div className="line" />
      <div className="line" />
      <div className="line" />
      <div className="line" />
    </div>
  );
}

export default Lines;
