import "./AccessibilityPortfolio.css";
import MiniStepper from "../../components/MiniStepper/MiniStepper";
import Dot from "../../components/Dot/Dot";

function AccessibilityPortfolio(props) {
  return (
    <div className="accessibility-portfolio-container">
      <div className="accessibility-portfolio-content">
        <h1 className="accessibility-h1">
          {props.title} <Dot color="black" />
        </h1>
        {props.simple ? (
          <>
            <div className="accessibility-stepper-container">
              <MiniStepper color={props.color} steps={props.steps} />
            </div>
            {props.children}
          </>
        ) : (
          <>{props.children}</>
        )}
      </div>
    </div>
  );
}

export default AccessibilityPortfolio;
