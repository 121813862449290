import "./ThreeColumn.css";

function ThreeColumn(props) {
  return (
    <div className="three-column-mixed-container">
      <div className="three-column-item three-column-item--desktop">
        <h3 className="three-column-h3">DESKTOP</h3>
        <img className="three-column-img--desktop" src={props.imgDesktop} />
      </div>
      <div className="three-column-item three-column-item--tablet">
        <h3 className="three-column-h3">TABLET</h3>
        <img className="three-column-img--tablet" src={props.imgTablet} />
      </div>
      <div className="three-column-item three-column-item--mobile">
        <h3 className="three-column-h3">MOBILE</h3>
        <img className="three-column-img--mobile" src={props.imgMobile} />
      </div>
    </div>
  );
}

export default ThreeColumn;
