import "./FinalDesignPortfolio.css";
import { useState } from "react";
import Dot from "../../components/Dot/Dot";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

function FinalDesignPortfolio(props) {
  const [open, setOpen] = useState({ state: false, content: "simple" });

  const imgLightBox = props.img ? props.img : props.complexImg[0];

  return (
    <div className="final-design-container common-container">
      <div className="final-design-content">
        <h2 className="common-title">
          The Final Design <Dot color="black" />
        </h2>
        {!props.complexImg ? (
          <>
            <div className="final-design-description-container">
              <h3 className="common-subtitle">Lessons Learned</h3>
              <p className="common-description">{props.description}</p>
            </div>
            <div className="final-design-img-container">
              <h3 className="common-subtitle">Main Flow</h3>
              <img
                src={props.img}
                className="final-design-img"
                onClick={() => setOpen({ state: true, content: "simple" })}
              />
              <img
                src={props.imgMobile}
                className="final-design-img-mobile"
                onClick={() => setOpen({ state: true, content: "simple" })}
              />
            </div>
          </>
        ) : (
          <>
            <div className="final-design-description-container">
              <h3 className="common-subtitle">Lessons Learned</h3>
              <p className="common-description">{props.description}</p>
            </div>
            <div className="final-design-img-container">
              <h3 className="common-subtitle">Flow Mobile App</h3>
              <img
                src={props.complexImg[0]}
                className="final-design-img"
                onClick={() => setOpen({ state: true, content: "complex" })}
              />
              <img
                src={props.complexImg[2]}
                className="final-design-img-mobile"
                onClick={() => setOpen({ state: true, content: "complex" })}
              />
            </div>
            <div className="final-design-img-container">
              <h3 className="common-subtitle">Flow Website</h3>
              <img
                src={props.complexImg[1]}
                className="final-design-img"
                onClick={() => setOpen({ state: true, content: "complex2" })}
              />
              <img
                src={props.complexImg[3]}
                className="final-design-img-mobile"
                onClick={() => setOpen({ state: true, content: "complex2" })}
              />
            </div>
          </>
        )}
      </div>
      <Lightbox
        open={open.state}
        close={() => setOpen({ state: false, content: "simple" })}
        slides={[
          {
            src:
              open.content === "simple"
                ? props.img
                : open.content === "complex"
                ? props.complexImg[0]
                : props.complexImg[1],
          },
        ]}
        plugins={[Fullscreen, Zoom]}
      />
    </div>
  );
}

export default FinalDesignPortfolio;
