import "./Contact.css";
import Lines from "../../components/Lines/Lines";
import Rectangle from "../../components/Rectangle/Rectangle";
import Dot from "../../components/Dot/Dot";
import Button from "../../components/Button/Button";

function Contact(props) {
  return (
    <div className="contact-container">
      <Lines />
      <div className="contact-text-container">
        <div className="contact-text-block">
          <div className="contact-text-first-line-container">
            <span className="contact-text-claim">
              Would you like to work with me?
            </span>
            <div className="contact-exclamation-mark">
              <Rectangle width="12px" height="145px" color="black" />
              <Dot color="black" />
            </div>
          </div>
          <div className="contact-email-download-container">
            <a href="mailto:wysockatreder@gmail.com">
              <span className="contact-email" />
            </a>
            <div className="contact-button-container">
              <div className="contact-button-wrapper">
                <a
                  className="button-container"
                  target="_blank"
                  href="https://drive.google.com/file/d/1j1Tt-xUmwfmHw7l4zSyvSzXLpP3K9kYC/view?usp=sharing"
                >
                  Download my resume
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
