import "./SocialButton.css";

function SocialButton(props) {
  return (
    <div className="social-button-container">
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        <img src={props.src} alt={props.alt} />
      </a>
    </div>
  );
}

export default SocialButton;
