import "./List.css";

function List(props) {
  return (
    <article className="list-container">
      <div>
        <h2 className="list-title">{props.title}</h2>
        <ul className="list-ul">
          {props.complex
            ? props.items.map((item, index) => (
                <li key={index}>
                  <div className="list-complex-dates">
                    <time dateTime={item.startDate}>{item.startDate}</time>-
                    <time dateTime={item.endDate}>{item.endDate}</time>
                  </div>
                  <span className="list-content">{item.content}</span>
                </li>
              ))
            : props.items.map((item, index) => (
                <li key={index} className="list-content-simple">
                  {item}
                </li>
              ))}
        </ul>
      </div>
    </article>
  );
}

export default List;
