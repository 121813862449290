import "./SectionHeader.css";
import Dot from "../../components/Dot/Dot";
import Lines from "../../components/Lines/Lines";
import Rectangle from "../../components/Rectangle/Rectangle";

function SectionHeader(props) {
  return (
    <header
      className={`section-header-container ${
        props.color === "mint" ? "section-header-mint" : "section-header-gold"
      }`}
    >
      {props.lines ? (
        <div className="section-header-lines">
          <Lines />
          <Rectangle color="black" width="52px" height="366px" />
        </div>
      ) : (
        <div className="section-header-rectangle">
          <Rectangle color="black" width="52px" height="366px" />
        </div>
      )}
      <div className="section-header-heading-container">
        <h2 className="section-header-h2">{props.title}</h2>
        <Dot color="black"/>
      </div>
    </header>
  );
}

export default SectionHeader;
