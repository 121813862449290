import "./ViewOtherProjects.css";
import { projects } from "../../data/data.js";
import WorkBoxSimple from "../../components/WorkBox/WorkBoxSimple";

function ViewOtherProjects(props) {
  const realUrl = window.location.href;
  const url = realUrl.split("/")[3];

  const projectsFiltered = projects.filter((project) => project.url !== url);

  return (
    <div className="view-other-projects-container common-container">
      <h1 className="common-subtitle">View My Other Projects</h1>
      <div className="view-other-projects-content">
        {projectsFiltered.map((project) => (
          <WorkBoxSimple
            title={project.title}
            img={project.img}
            url={project.url}
          />
        ))}
      </div>
    </div>
  );
}

export default ViewOtherProjects;
