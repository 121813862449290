import "./Footer.css";
import Lines from "../../components/Lines/Lines";
import Dot from "../../components/Dot/Dot";
import SocialButton from "../../components/SocialButton/SocialButton";
/* Social Media icons */
import Behance from "../../assets/vectors/behance.svg";
import Dribble from "../../assets/vectors/dribble.svg";
import Linkedin from "../../assets/vectors/linkedin.svg";

function Footer(props) {
  const year = new Date().getFullYear();

  return (
    <footer className="footer-container">
      <div className="footer-content-container">
        <div className="footer-social-buttons">
          <SocialButton href="" src={Behance} alt="Behance" />
          <SocialButton href="https://dribbble.com/josephmc" src={Dribble} alt="Dribble" />
          <SocialButton href="https://www.linkedin.com/in/joseph-m-c/" src={Linkedin} alt="Linkedin" />
        </div>
        <div className="footer-copy">
          <span>© {year} Emilia Wysocka-Treder</span> <span>e-mail: wysockatreder@gmail.com</span>
          <Dot color="gold" />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
