import "./Emos.css";
/* Components */
import MocksPortfolio from "../../containers/MocksPortfolio/MocksPortfolio";
import NarrowContent from "../../components/NarrowContent/NarrowContent";
import ExternalLinkWrapper from "../../components/ExternalLinkWrapper/ExternalLinkWrapper";
import ButtonWrapper from "../../components/ButtonWrapper/ButtonWrapper";
import MiniStepper from "../../components/MiniStepper/MiniStepper";
import BeforeAfter from "../../components/BeforeAfter/BeforeAfter";
import WideTwoColumn from "../../components/WideTwoColumn/WideTwoColumn";
import ThreeColumn from "../../components/ThreeColumn/ThreeColumn";
/* Containers */
import Nav from "../../containers/Nav/Nav";
import Footer from "../../containers/Footer/Footer";
import HeroPortfolio from "../../containers/HeroPortfolio/HeroPortfolio";
import OverviewPortfolio from "../../containers/OverviewPortfolio/OverviewPortfolio";
import ResearchPortfolio from "../../containers/ResearchPortfolio/ResearchPortfolio";
import AccessibilityPortfolio from "../../containers/AccessibilityPortfolio/AccessibilityPortfolio";
import VisualDesignPortfolio from "../../containers/VisualDesignPortfolio/VisualDesignPortfolio";
import FinalDesignPortfolio from "../../containers/FinalDesignPortfolio/FinalDesignPortfolio";
import ViewOtherProjects from "../../containers/ViewOtherProjects/ViewOtherProjects";
/* Assets */
import Emos_1 from "../../assets/img/emos/emos_1.png";
import Emos_wire_1 from "../../assets/img/emos/emos_wire_1.png";
import Emos_wire_2 from "../../assets/img/emos/emos_wire_2.png";
import Emos_wire_3 from "../../assets/img/emos/emos_wire_3.png";
import Emos_wire_4 from "../../assets/img/emos/emos_wire_4.png";
import Emos_wire_5 from "../../assets/img/emos/emos_wire_5.png";
import Emos_wire_6 from "../../assets/img/emos/emos_wire_6.png";
import Emos_wire_7 from "../../assets/img/emos/emos_wire_7.png";
import Emos_mock_1 from "../../assets/img/emos/emos_mock_1.png";
import Emos_mock_2 from "../../assets/img/emos/emos_mock_2.png";
import Emos_mock_3 from "../../assets/img/emos/emos_mock_3.png";
import Emos_mock_4 from "../../assets/img/emos/emos_mock_4.png";
import Emos_mock_5 from "../../assets/img/emos/emos_mock_5.png";
import Emos_mock_6 from "../../assets/img/emos/emos_mock_6.png";
import Emos_type_1 from "../../assets/img/emos/emos_type_1.png";
import Emos_type_2 from "../../assets/img/emos/emos_type_2.png";
import Emos_type_3 from "../../assets/img/emos/emos_type_3.png";
import Emos_type_4 from "../../assets/img/emos/emos_type_4.png";
import Emos_type_5 from "../../assets/img/emos/emos_type_5.png";
import Emos_type_6 from "../../assets/img/emos/emos_type_6.png";
import Emos_type_7 from "../../assets/img/emos/emos_type_7.png";
import Emos_type_8 from "../../assets/img/emos/emos_type_8.png";
import Emos_type_9 from "../../assets/img/emos/emos_type_9.png";
import Emos_type_10 from "../../assets/img/emos/emos_type_10.png";
import Emos_type_11 from "../../assets/img/emos/emos_type_11.png";
import Emos_type_12 from "../../assets/img/emos/emos_type_12.png";
import Emos_flow_1 from "../../assets/img/emos/emos_flow_1.png";
import Emos_flow_2 from "../../assets/img/emos/emos_flow_2.png";
import Emos_flow_1_mobile from "../../assets/img/emos/emos_flow_1_mobile.png";
import Emos_flow_2_mobile from "../../assets/img/emos/emos_flow_2_mobile.png";
import Flo_persona1 from "../../assets/img/flo/flo_persona1.png";

function Emos() {
  const url =
    "https://www.figma.com/proto/OEBIHovs0pIyUEB1y8KQMN/EMOS?node-id=107%3A1660&viewport=926%2C544%2C0.22&scaling=min-zoom&starting-point-node-id=107%3A1716";
  const stickerSheetUrl = "";
  return (
    <>
      <Nav hideElements pink />
      <div className="box-container commmon-portfolio-container">
        <HeroPortfolio
          title="emos — mobile app & website design"
          description="Mobile app with games to teach kids age 2-5 about emotions + responsive website design with resources for parents."
          url={url}
          label="see prototype"
          img={Emos_1}
          beforeHeight="500"
          color="red"
          imgKind="web"
        />
        <OverviewPortfolio
          title="overview"
          role="ux design lead"
          duration="JUNE 2022/ JULY 2022"
          client="GROW WITH GOOGLE, UX DESIGN COURSE"
          product="EMOS is an app that teaches preschoolers (age 2-5) about emotions through games designed by developmental specialists to support children's social-emotional growth. The app aims to help children understand and identify their emotions and develop emotional regulation."
          goal="Design an app with age appropriate games: 2-3, 3-4, 4-5 under careful eyes of specialists to supports kids emotional growth.
          The mission of this project is to teach kids that there are no bad emotions and every emotion has a purpose."
          challenge="During pandemic little children were less exposed to contact with others and their social-emotional skills are being compromised. Resources that are available on the market lack the age-appropriate games that teach younger kids about emotions."
        />
        <ResearchPortfolio
          process={[
            "inteviews",
            "personas",
            "user stories",
            "competitive audit",
          ]}
          processDescription="To begin the design process, I consulted with an Early Childhood Development specialist to write interview questions and conducted interviews with 5 parents of children aged 2-5 to gather insights. From the information gathered, I created personas, problem statements, and user stories. 

          I also conducted a competitive audit of 5 apps (2 direct and 3 indirect) to understand the market and identify areas for improvement. This helped me to fully understand the needs of the target users and design a solution that meets their needs."
          problemStatement="Camille is a mother of preschooler on autism spectrum who needs resources for her kid to learn how to recognize his and other emotions becasue she wants her kid  to open up socially."
          personaImage={Flo_persona1}
          personaQuote="“I want to set up my preschooler for success and know how to deal with their emotions.”"
          name="Camille"
          age="40"
          education="Master degree"
          hometown="San Jose, CA"
          family="1 kid"
          occupation="accountant"
          goals={[
            "Have parental control and content overview",
            "Variety of games that teach children how to deal with emotions and recognize them",
          ]}
          frustrations={[
            "Some games take too long and my child looses interest",
            "Lack of printable resources",
          ]}
          painpointsDescription="The interviews I conducted helped me identify the areas of frustration for parents, such as the lack of age-appropriate games for younger children and a desire for parental control over content. They also found games created by developmental specialists to be beneficial. This feedback informed my design choices and led to the creation of an app with age-appropriate games and a parent's section for control and access to resources."
          competitiveAudit="I conducted a competitive audit of 5 companies and found that there was no distinction of games based on age, which was a need identified during user interviews. This helped inform the design of the EMOS app to provide age-appropriate games for children's developmental needs."
        />
        {/* Low fidelity section */}
        <MocksPortfolio
          processTitle="low fidelity"
          processDescription="To begin the prototype phase, I started by sketching paper wireframes. After evaluating which designs best addressed the project challenges, I moved on to creating digital wireframes in Figma and a low-fidelity prototype. This allowed me to test the design and make any necessary adjustments before moving on to the high-fidelity prototype and final development."
          process={[
            "paper wireframes",
            "digital wireframes",
            "low-fidelity prototype",
            "usability study",
          ]}
        >
          <div className="common-mocks-container">
            <h2 className="common-subtitle"> WIREFRAMES — MOBILE APP</h2>
            <div className="common-content">
              <NarrowContent
                title="AGE-APPROPRIATE GAMES"
                description="There are 3 age categories with games designed for each stage of child’s development. I based this feature on one of the pain points listed by the parents I interviewed during the empathize stage."
                image={Emos_wire_1}
              />
              <NarrowContent
                title="LIST OF BENEFITS"
                description="Each games has a list of benefits so that parents can easily see what is the area of focus of the games and which social-emotional skills they help improve."
                image={Emos_wire_2}
              />
              <NarrowContent
                title="PROFILE PAGE"
                description="On the profile page parents can track the progress their child made. They can see recommended games based on the results their kids achieved."
                image={Emos_wire_3}
              />
            </div>
            <ExternalLinkWrapper
              url="https://www.figma.com/proto/OEBIHovs0pIyUEB1y8KQMN/EMOS?node-id=28%3A117&viewport=112%2C766%2C0.42&scaling=scale-down&starting-point-node-id=28%3A166&show-proto-sidebar=1"
              label="Low Fidelity Prototype"
              color="red"
            />
            <h2 className="common-subtitle"> WIREFRAMES — WEBSITE</h2>
            <div className="common-content">
              <WideTwoColumn
                titleLeft="RESOURCES FOR PARENTS"
                textLeft="The main goal for the website was to supplement the mobile app.
                Parents can find different resources on the EMOS website from book and videos recommendations to printable games they can play together with their kids. "
                imgLeft={Emos_wire_4}
                titleRight="EMOTIONAL DEVELOPMENT"
                textRight="Parents can find out more about what is happening with their kids developmentally in each age stage and how EMOS games are contributing and supporting the emotional growth."
                imgRight={Emos_wire_5}
              />
            </div>
            <ExternalLinkWrapper
              url="https://www.figma.com/proto/OEBIHovs0pIyUEB1y8KQMN/EMOS?node-id=149%3A745&viewport=581%2C560%2C0.12&scaling=min-zoom&starting-point-node-id=149%3A746"
              label="Low Fidelity Prototype"
              color="red"
            />
          </div>
          <div className="common-before-after-container">
            <h2 className="common-subtitle">1st Usability Study Findings</h2>
            <MiniStepper
              color="red"
              steps={[
                "According to the participants the log in screen came to early in the app flow. They didn’t want to register before playing the games.",
                "Participants want to see the list of benefits of each game.",
                "There was no easy way to navigate back to the game from Game Results Screen.",
              ]}
            />
            <BeforeAfter beforeImg={Emos_wire_6} afterImg={Emos_wire_7} />
          </div>
        </MocksPortfolio>
        {/* High fidelity section */}
        <MocksPortfolio
          processTitle="high fidelity"
          processDescription="After iterating on the low-fidelity prototype, I moved on to creating mockups. First, I designed all the UI elements needed for the app, including faces and different emotion characteristics and chose icons. 
          With my UI kit ready, I moved on to the high-fidelity prototype of the app. The next step was to design a responsive website, optimizing the layout and design for mobile, tablet, and desktop screen sizes."
          process={["mockups", "high fieldity prototype", "usability study"]}
        >
          <div className="common-mocks-container">
            <h2 className="common-subtitle"> MOCKUPS — MOBILE APP</h2>
            <div className="common-content">
              <NarrowContent
                title="RECOMMENDATIONS"
                description="Upon entering each age category, parents have few games to choose from with a “Recommended to start with” label on the easiest one of them."
                image={Emos_mock_1}
              />
              <NarrowContent
                title="DIFFERENT SCENARIOS"
                description="One of the games shows how we might express our emotions in different places. Parents can easily choose and play a video from 3 different scenarios."
                image={Emos_mock_2}
              />
              <NarrowContent
                title="EASY WAY OUT"
                description="When you are done playing the game you can choose to sign up to see the results or exit and continue playing without the account."
                image={Emos_mock_3}
              />
            </div>
            <ExternalLinkWrapper
              url="https://www.figma.com/proto/OEBIHovs0pIyUEB1y8KQMN/EMOS?node-id=107%3A1660&viewport=926%2C544%2C0.22&scaling=min-zoom&starting-point-node-id=107%3A1716"
              label="High Fidelity Prototype"
              color="red"
            />
          </div>
          <div className="common-before-after-container">
            <h2 className="common-subtitle">Mockups - website</h2>
            <div className="common-three-column-container">
              <h3 className="common-h3">
                DESIGNING FOR 3 DIFFERENT SCREEN SIZES
              </h3>
              <p className="common-p">
                Designing for responsive website I needed to take under
                consideration how to resize the text, images and call-to-action
                buttons to ensure good readability and temporary accessibility
                issues for users on the go.
              </p>
              <ThreeColumn
                imgDesktop={Emos_mock_4}
                imgTablet={Emos_mock_5}
                imgMobile={Emos_mock_6}
              />
            </div>
          </div>
        </MocksPortfolio>
        <AccessibilityPortfolio
          simple
          title="Accessibility"
          color="red"
          steps={[
            "EMOS game app is targeted towards younger kids, with that in mind I designed each clickable element within the game to be large enough to be easily pressed.",
            "On top large having large touch targets in the app I also made sure the spacing between them is at least 30 px to avoid errors.",
            "Color contrast and font size was an important part of accessibility considerations. I used Figma plugin: A11y to ensure that the text is readble and followed IOS font size guidelines using the minimal font size 16px.",
          ]}
        />
        <VisualDesignPortfolio
          description="As a designer, color psychology is an area I'm passionate about. This project was an opportunity for me to explore this further. I drew inspiration for the color palette from children's books, focusing on how colors can reflect different emotions. I used the book The Color Monster by Anna Llenas to deepen my research on this topic. For typography, I combined two different types: Sen, a geohumanist sans-serif for body copy and headlines, and Chelsea Market, a playful typeface used for accents such as H2 and H3. I chose these two typefaces because they have similar characteristics in letters, yet provide a contrasting visual style."
          colors={[
            { color: "#FFF9F6", text: "#241F1D" },
            { color: "#C8553D", text: "#FBF9F7" },
            { color: "#F7E0DA", text: "#241F1D" },
            { color: "#463D3B", text: "#FBF9F7" },
          ]}
          complexColors={[
            {
              label: "Anger",
              color1: { color: "#C8553D", text: "#FBF9F7" },
              color2: { color: "#F1D5CE", text: "#241F1D" },
            },
            {
              label: "Happiness",
              color1: { color: "#F28F3B", text: "#FBF9F7" },
              color2: { color: "#FBDABE", text: "#241F1D" },
            },
            {
              label: "Surprise",
              color1: { color: "#FFD5C2", text: "#241F1D" },
              color2: { color: "#FFF2EC", text: "#241F1D" },
            },
            {
              label: "Sadness",
              color1: { color: "#93B7BE", text: "#241F1D" },
              color2: { color: "#DFE9EC", text: "#241F1D" },
            },
            {
              label: "Fear",
              color1: { color: "#588B8B", text: "#FBF9F7" },
              color2: { color: "#CBDBDB", text: "#241F1D" },
            },
            {
              label: "Disgust",
              color1: { color: "#598B58", text: "#FBF9F7" },
              color2: { color: "#C5D6C4", text: "#241F1D" },
            },
          ]}
          complexType={[
            {
              label: "MOBILE APP",
              fonts: [Emos_type_1, Emos_type_2, Emos_type_3, Emos_type_4],
            },
            {
              label: "WEBSITE (DESKTOP)",
              fonts: [Emos_type_5, Emos_type_6, Emos_type_7, Emos_type_8],
            },
            {
              label: "WEBSITE (Mobile)",
              fonts: [Emos_type_9, Emos_type_10, Emos_type_11, Emos_type_12],
            },
          ]}
          color="blue"
          label="sticker sheet"
          url={stickerSheetUrl}
        />
        <FinalDesignPortfolio
          description="EMOS was my first project where I designed for both a mobile app and responsive website. I learned how to make the designs complementary and how to create a cohesive style across all platforms. 

          I also drew the illustrations from scratch in Figma, and developed a more complex color palette to support emotional learning for children, with each color representing a specific emotion. The biggest lesson was the importance of creating a consistent style across all platforms to ensure they complement each other."
          complexImg={[
            Emos_flow_1,
            Emos_flow_2,
            Emos_flow_1_mobile,
            Emos_flow_2_mobile,
          ]}
        />
        <ViewOtherProjects />
        <Footer />
      </div>
    </>
  );
}

export default Emos;
