import "./BigStepper.css";
import Dot from "../Dot/Dot";

function BigStepper(props) {
    return (
        <div className="bigstepper-container">
        <h1 className="bigstepper-title">{props.title}<Dot color="black" /></h1>
        <div className="bigstepper-process-container">
            <h2 className="bigstepper-subtitle">{props.subtitle}</h2>
            <ol className="bigstepper-list">
                {props.steps.map((item, index) => (
                    <li className="bigstepper-item" key={`process${index}`}>
                        {props.steps[index]}
                    </li>))}
            </ol>
            <p className="bigstepper-description">
                {props.description}
            </p>
        </div>
    </div>
    );
}

export default BigStepper;