import "./Dot.css";

function Dot(props) {
  return (
    <div
      className={`dot-container ${
        props.color === "black"
          ? "dot-container-black"
          : props.color === "mint"
          ? "dot-container-mint"
          : "dot-container-gold"
      }`}
    />
  );
}

export default Dot;
