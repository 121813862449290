import "./ResearchPortfolio.css";
import Dot from "../../components/Dot/Dot";

function ResearchPortfolio(props) {
    return (
        <div className="research-portfolio-container">
            <div className="research-portfolio-content">
                <h1 className="overview-portfolio-title">User Research<Dot color="black" /></h1>
                <div className="research-portfolio-process-container">
                    <h2 className="research-portfolio-subtitle">The Process</h2>
                    <ol className="research-portfolio-process-list">
                        {props.process.map((item, index) => (
                            <li className="research-portfolio-process-item" key={`process${index}`}>
                                {props.process[index]}
                            </li>))}
                    </ol>
                    <p className="research-portfolio-process-description">
                        {props.processDescription}
                    </p>
                </div>
                <div className="research-portfolio-two-sections-container">
                    <div className="research-portfolio-persona-container">
                        <h2 className="research-portfolio-subtitle">Persona</h2>
                        <div className="research-portfolio-problem-container">
                            <h3 className="research-portfolio-problem-header">Problem Statement</h3>
                            <p className="research-portfolio-problem-statement">{props.problemStatement}</p>
                        </div>
                        <div className="research-portfolio-persona-basics-container">
                            <img className="research-portfolio-persona-image" src={props.personaImage} alt="Persona" />
                            <p className="research-portfolio-persona-quote">{props.personaQuote}</p>
                        </div>
                        <div className="research-portfolio-persona-lists">
                            <ul className="research-portfolio-persona-details">
                                <h4 className="research-portfolio-persona-h4">{props.name}</h4>
                                <li className="research-portfolio-persona-item">
                                    <span>
                                        <h5 className="research-portfolio-persona-item-title">Age:</h5>
                                        <p className="research-portfolio-persona-item-description">{props.age}</p>
                                    </span>
                                </li>
                                <li className="research-portfolio-persona-item">
                                    <span>
                                        <h5 className="research-portfolio-persona-item-title">Education:</h5>
                                        <p className="research-portfolio-persona-item-description">{props.education}</p>
                                    </span>
                                </li>
                                <li className="research-portfolio-persona-item">
                                    <span>
                                        <h5 className="research-portfolio-persona-item-title">Hometown:</h5>
                                        <p className="research-portfolio-persona-item-description">{props.hometown}</p>
                                    </span>
                                </li>
                                <li className="research-portfolio-persona-item">
                                    <span>
                                        <h5 className="research-portfolio-persona-item-title">Family:</h5>
                                        <p className="research-portfolio-persona-item-description">{props.family}</p>
                                    </span>
                                </li>
                                <li className="research-portfolio-persona-item">
                                    <span>
                                        <h5 className="research-portfolio-persona-item-title">Occupation:</h5>
                                        <p className="research-portfolio-persona-item-description">{props.occupation}</p>
                                    </span>
                                </li>
                            </ul>
                            <ul className="research-portfolio-persona-goals">
                                <h4 className="research-portfolio-persona-h4">Goals</h4>
                                {props.goals.map((item, index) => (
                                    <li className="research-portfolio-persona-item" key={`goals${index}`}>
                                        {props.goals[index]}
                                    </li>))}
                            </ul>
                            <ul className="research-portfolio-persona-frustrations">
                                <h4 className="research-portfolio-persona-h4">Frustrations</h4>
                                {props.frustrations.map((item, index) => (
                                    <li className="research-portfolio-persona-item" key={`frustration${index}`}>
                                        {props.frustrations[index]}
                                    </li>))}
                            </ul>
                        </div>
                    </div>
                    <div className="research-portfolio-pain-points-container">
                        <h2 className="research-portfolio-subtitle">Pain Points</h2>
                        <div className="research-portfolio-pain-points-content">
                            <p className="research-portfolio-pain-points-description">{props.painpointsDescription}</p>
                            {props.painpoints ? (
                                <ol className="research-portfolio-pain-points-list">
                                    {props.painpoints.map((item, index) => (
                                        <li className="research-portfolio-pain-points-item" key={`painpoint${index}`}>
                                            <span className={`research-portfolio-pain-numbers research-portfolio-pain-numbers--${props.color}`}>{index + 1}</span>
                                            <span>
                                                <span className="research-portfolio-pain-points-item--title">
                                                    {props.painpoints[index][0]}.&nbsp;
                                                </span>
                                                <span className="research-portfolio-pain-points-item--desc">
                                                    {props.painpoints[index][1]}
                                                </span>
                                            </span>
                                        </li>))}
                                </ol>) : null}
                        </div>
                        {props.competitiveAudit ? (
                            <div className="research-portfolio--competitive">
                                <h2 className="research-portfolio-subtitle">Competitive Audit</h2>
                                <p className="research-portfolio-pain-points-description">{props.competitiveAudit}</p>
                            </div>
                        ) : null}
                    </div>

                </div>

                <p className="research-portfolio-description">{props.description}</p>
            </div>
        </div >
    );
}

export default ResearchPortfolio;