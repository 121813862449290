import "./NarrowContent.css";

function NarrowContent(props) {
    return (
        <article className="narrow-content-column">
            <h3 className="narrow-content-column-title">
                {props.title}</h3>
            <p className="narrow-content-column-description">
                {props.description}
            </p>
            <img src={props.image} className="narrow-content-column-image" alt="wireframe" />
        </article>
    );
}

export default NarrowContent;