import "./BeforeAfter.css";

function BeforeAfter(props) {
    return (<div className="before-after-container">
        <div className="before-after-content">
            <h3 className="before-after-title">Before</h3>
            <img src={props.beforeImg} className="before-after-image" alt="before" />
        </div>
        <div className="before-after-content">
            <h3 className="before-after-title">After</h3>
            <img src={props.afterImg} className="before-after-image" alt="after" />
        </div>
    </div>);
}

export default BeforeAfter;