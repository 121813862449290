import "./TrustedTwinWebsite.css";
/* Components */
import WideTwoColumn from "../../components/WideTwoColumn/WideTwoColumn";
/* Containers */
import Nav from "../../containers/Nav/Nav";
import Footer from "../../containers/Footer/Footer";
import HeroPortfolio from "../../containers/HeroPortfolio/HeroPortfolio";
import OverviewPortfolio from "../../containers/OverviewPortfolio/OverviewPortfolio";
import ResearchPortfolio from "../../containers/ResearchPortfolio/ResearchPortfolio";
import MocksPortfolio from "../../containers/MocksPortfolio/MocksPortfolio";
import AccessibilityPortfolio from "../../containers/AccessibilityPortfolio/AccessibilityPortfolio";
import VisualDesignPortfolio from "../../containers/VisualDesignPortfolio/VisualDesignPortfolio";
import FinalDesignPortfolio from "../../containers/FinalDesignPortfolio/FinalDesignPortfolio";
import ViewOtherProjects from "../../containers/ViewOtherProjects/ViewOtherProjects";
/* Assets */
import TT_website_1 from "../../assets/img/tt_website_redesign/tt_website_redesign1.png";
import TT_website_2 from "../../assets/img/tt_website_redesign/tt_website_redesign2.png";
import TT_website_3 from "../../assets/img/tt_website_redesign/tt_website_redesign3.png";
import TT_website_4 from "../../assets/img/tt_website_redesign/tt_website_redesign4.png";
import TT_website_5 from "../../assets/img/tt_website_redesign/tt_website_redesign5.png";
import TT_website_type1 from "../../assets/img/tt_website_redesign/tt_website_redesign_type1.png";
import TT_website_type2 from "../../assets/img/tt_website_redesign/tt_website_redesign_type2.png";
import TT_website_type3 from "../../assets/img/tt_website_redesign/tt_website_redesign_type3.png";
import TT_website_type4 from "../../assets/img/tt_website_redesign/tt_website_redesign_type4.png";
import TT_website_flow from "../../assets/img/tt_website_redesign/tt_website_redesign_flow.png";
import TT_website_flow_mobile from "../../assets/img/tt_website_redesign/tt_website_redesign_flow_mobile.png";

function TrustedTwinWebsite() {
  const stickerSheetUrl = "";
  return (
    <>
      <Nav hideElements pink />
      <div className="box-container commmon-portfolio-container">
        <HeroPortfolio
          title="TRUSTED TWIN — WEBSITE REDESIGN"
          description="Responsive website redesign for data sharing cloud digital twin start-up based in Poland."
          url="http://TRUSTEDTWIN.COM"
          label="TRUSTEDTWIN.COM"
          img={TT_website_1}
          beforeHeight="500"
          color="red-dark"
          imgKind="web"
        />
        <OverviewPortfolio
          title="overview"
          role="ux design lead"
          duration="JULY 2022"
          client="TRUSTED TWIN"
          product="Trusted Twin is a Polish start-up that provides a secure and efficient solution for sharing operational data with multiple partners using Digital Twins technology."
          goal="The client reached out to me for a website redesign right before the launch. My goal was to create a unique design that stands out from competitors, by updating the color palette and overall design."
          challenge="Since I worked on a tight deadline and I needed to ensure that I deliver a high-quality design that clearly communicates the product's value and its unique features compared to its competitors."
        />
        <MocksPortfolio
          processTitle="MID & HIGH FIDELITY"
          process={[
            "paper wireframes",
            "MID-FIDELITY DIGITAL WIREFRAMES",
            "HIGH-FIDELITY PROTOTYPE",
          ]}
          processDescription="Before beginning the design process, I conducted a competitive analysis to understand how Trusted Twin's competitors present their products on their websites and identify opportunities for Trusted Twin to differentiate itself. I found that the existing design was too similar to a direct competitor in terms of layout, illustrations, and color palette. I then created paper wireframes and transferred them to Figma to develop a mid-fidelity prototype, which I shared with the client for feedback."
        >
          <div className="common-container">
            <h2 className="common-subtitle">MID-FIDELITY</h2>
            <WideTwoColumn
              titleLeft="BEFORE REDESIGN"
              textLeft="The original design read as too conservative and indistinct, blending in with that of the competition. To achieve my goal, I reimagined the color palette and added custom illustrations, bringing a warm and personal touch to the website and setting Trusted Twin apart."
              imgLeft={TT_website_2}
              titleRight="After REDESIGN"
              textRight="I added high-quality product screenshots to help users better understand what Trusted Twin is and how it works. This was important because it's a new product, and it was crucial to present it in a way that is easy to understand and navigate."
              imgRight={TT_website_3}
            />
          </div>
          <div className="common-container">
            <h2 className="common-subtitle">HIGH-FIDELITY</h2>
            <WideTwoColumn
              titleLeft="MONOSPACE TYPEFACE"
              textLeft="I used the monospace typeface DM Mono to highlight the character of the product. I decided to use brackets around H2s to refer to the code language used within the product.
              These design choices helped effectively communicate the product's technical nature and add to the overall user experience."
              imgLeft={TT_website_4}
              titleRight="BUTTONS"
              textRight="I also added hover effects to the CTAs to make them more interactive and engaging for the users. Overall, the redesign helped Trusted Twin stand out from their competitors and made the website more user-friendly and easy to understand for potential customers."
              imgRight={TT_website_5}
            />
          </div>
        </MocksPortfolio>
        <AccessibilityPortfolio
          simple
          title="Accessibility"
          color="red-dark"
          steps={[
            "By making sure the headlines are well-designed and arranged in a clear and intuitive way, I made it easier for users to navigate and find the information they're looking for.",
            "I took care in ensuring that the buttons on the website were not only visually striking but also met the contrast ratio standards.",
            "With the user's experience in mind, I selected typefaces that exuded both elegance and legibility.",
          ]}
        />
        <VisualDesignPortfolio
          description="I wanted to make sure that the redesign of Trusted Twin's website would make it stand out from the competition and effectively communicate the unique features of their API-first solution. 
          I worked closely with the development team to understand the technical aspects of the product and incorporated those elements into the design through the use of a monospace typeface and references to code language. 
          The color palette was kept light and airy to evoke a sense of trust, and I made sure to include clear and detailed product screenshots to help users understand the capabilities of Trusted Twin's solution. Despite the tight deadline, I was able to deliver a redesign that the client was thrilled with, and that effectively highlighted the strengths of the product."
          colors={[
            { color: "#6AB5DF", text: "#FFF" },
            { color: "#C1121F", text: "#FFF" },
            { color: "#EDF6FC", text: "#171616" },
            { color: "#171616", text: "#FBF9F7" },
          ]}
          complexType={[
            {
              label: "",
              fonts: [TT_website_type1, TT_website_type2, TT_website_type3, TT_website_type4],
            },
          ]}
          color="red-dark"
          label="sticker sheet"
          url=""
        />
        <FinalDesignPortfolio
          description="I also learned the importance of understanding the client's product and industry in order to create a design that accurately represents and differentiates them from competitors. Overall, it was a great learning experience and I am proud of the final design that helped Trusted Twin stand out in the market."
          img={TT_website_flow}
          imgMobile={TT_website_flow_mobile}
        />
        <ViewOtherProjects />
        <Footer />
      </div>
    </>
  );
}

export default TrustedTwinWebsite;
