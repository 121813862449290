import "./WideContent.css";
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

function WideContent(props) {
  const [open, setOpen] = useState({ state: false, content: "simple" });

  return (
    <div className="wide-content-container">
      <div className="wide-content-text-container">
        <h3 className="wide-content-title">{props.title}</h3>
        <p className="wide-content-text">{props.text}</p>
        <img
          src={props.img}
          className="wide-content-image"
          onClick={() => setOpen({ state: true, content: "simple" })}
        />
      </div>
      <Lightbox
        open={open.state}
        close={() => setOpen({ state: false, content: "simple" })}
        slides={[
          {
            src: props.img,
          },
        ]}
        plugins={[Fullscreen, Zoom]}
      />{" "}
    </div>
  );
}

export default WideContent;
