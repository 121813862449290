import "./BoxOfGoods.css";
/* Components */
import WideTwoColumn from "../../components/WideTwoColumn/WideTwoColumn";
/* Containers */
import Nav from "../../containers/Nav/Nav";
import Footer from "../../containers/Footer/Footer";
import HeroPortfolio from "../../containers/HeroPortfolio/HeroPortfolio";
import OverviewPortfolio from "../../containers/OverviewPortfolio/OverviewPortfolio";
import ResearchPortfolio from "../../containers/ResearchPortfolio/ResearchPortfolio";
import MocksPortfolio from "../../containers/MocksPortfolio/MocksPortfolio";
import AccessibilityPortfolio from "../../containers/AccessibilityPortfolio/AccessibilityPortfolio";
import VisualDesignPortfolio from "../../containers/VisualDesignPortfolio/VisualDesignPortfolio";
import FinalDesignPortfolio from "../../containers/FinalDesignPortfolio/FinalDesignPortfolio";
import ViewOtherProjects from "../../containers/ViewOtherProjects/ViewOtherProjects";
/* Assets */
import Box_1 from "../../assets/img/box/box_1.png";
import Box_wire_1 from "../../assets/img/box/box_wire_1.png";
import Box_wire_2 from "../../assets/img/box/box_wire_2.png";
import Box_mocks_1 from "../../assets/img/box/box_mocks_1.png";
import Box_mocks_2 from "../../assets/img/box/box_mocks_2.png";
import Box_mocks_3 from "../../assets/img/box/box_mocks_3.png";
import Box_mocks_4 from "../../assets/img/box/box_mocks_4.png";
import Box_mocks_5 from "../../assets/img/box/box_mocks_5.png";
import Box_mocks_6 from "../../assets/img/box/box_mocks_6.png";
import Box_mocks_7 from "../../assets/img/box/box_mocks_7.png";
import Box_mocks_8 from "../../assets/img/box/box_mocks_8.png";
import Box_type_1 from "../../assets/img/box/box_type_1.png";
import Box_type_2 from "../../assets/img/box/box_type_2.png";
import Box_shape from "../../assets/img/box/box_shape.png";
import Box_flow from "../../assets/img/box/box_flow.png";
import Box_flow_mobile from "../../assets/img/box/box_flow_mobile.png";
import Flo_persona1 from "../../assets/img/flo/flo_persona1.png";

function BoxOfGoods() {
  const stickerSheetUrl = "";
  return (
    <>
      <Nav hideElements pink />
      <div className="box-container commmon-portfolio-container">
        <HeroPortfolio
          title="box of goods — web design"
          description="Responsive website design for Polish Humanitarian aid organization."
          url="http://boxofgoods.org"
          label="boxofgoods.org"
          img={Box_1}
          beforeHeight="500"
          color="blue"
          imgKind="web"
        />
        <OverviewPortfolio
          title="overview"
          role="ux design lead"
          duration="APRIL 2022/ MAY 2022"
          client="THE SHEHYNI HUMANITARIAN AID CENTER"
          product="The Shehyni Humanitarian Aid Center was founded by a group of Polish volounteers, who joined their forces to provide immediate help to people escaping from Russian invation on Ukraine. The Center gives direct help to the families affected by war."
          goal="The website will make it easy for users to purchase boxes of goods and donate to the foundation, helping to make a real difference in the lives of those who need it most."
          challenge="As a newly formed organization the challenge for the website design is to effectively communicate this mission and build trust with users, so they choose to support the organization."
        />
        <ResearchPortfolio
          process={[
            "inteviews",
            "personas",
            "pain points",
            "competitive audit",
          ]}
          processDescription="I interviewed people in the target group and created personas and problem statements to better understand their needs and how to differentiate the website experience of the Shehynia Aid Center from other NGO websites. I also conducted a competitive audit to highlight the unique mission of the organization."
          problemStatement="Ala is a young professional who wants to support NGO. Because of recent situation in Ukraine, she wants to find a way to donate money or goods online through trusted organization."
          personaImage={Flo_persona1}
          personaQuote="“I want to stay connected to my close ones and send them gifts for the celebrations I can’t attend.”"
          name="Dan"
          age="25"
          education="Master degree"
          hometown="Bend, Oregon"
          family="single"
          occupation="developer"
          goals={[
            "Send flowers as gifts to their relatives",
            "Have option to personalize bouquets to add a unique touch",
          ]}
          frustrations={[
            "Most flower services are old-fashion and don’t have many exciting choices",
            "All bouquets come premade, no room for customization",
          ]}
          painpointsDescription="People interested in donations often prefer supporting smaller NGOs and want transparency on where their donation is going. They want to know the journey of the package and have confirmation that the goods have been delivered. A lack of information on the legitimacy of the organization is a common area of frustration for donors."
          competitiveAudit="During my research on competitive NGOs, I was able to see how the most popular organizations operate online and what their donation model is. The competitive audit helped me understand how Shehynia Aid Center can differentiate itself from others and which areas need to be improved."
          color="blue"
        />
        <MocksPortfolio
          processTitle="low fidelity"
          process={[
            "paper wireframes",
            "digital wireframes",
            "low-fidelity prototype",
          ]}
          processDescription="As a designer, I like to start by understanding the problem at hand and setting clear goals for the project. So, after defining the problem and setting goals, I got to work sketching out my ideas on paper. I then used Figma to bring those sketches to life and create a low-fidelity prototype. It was a fun and exciting process to see my ideas come to life on the screen."
        >
          <div className="common-container">
            <h2 className="common-subtitle">Wireframes</h2>
            <WideTwoColumn
              titleLeft="ABOVE FOLD"
              textLeft="My goal for the website of the Shehyni Aid Center was to quickly communicate what the organization does, build trust and guide users towards the main action on the website which is purchasing boxes of goods for Ukrainian families in need."
              imgLeft={Box_wire_1}
              titleRight="DONATE TO THE FOUNDATION"
              textRight="As per client's request, the website was also supposed to be a place where users can place donations to the foundation. To ensure that the main action of purchasing boxes of goods for Ukrainian families in need was not overshadowed, I created a dedicated section on the website for donations."
              imgRight={Box_wire_2}
            />
          </div>
        </MocksPortfolio>
        <MocksPortfolio
          processTitle="high fidelity"
          processDescription="As this project was time-sensitive, I wanted to make sure that I deliver a solution that is not too complicated to code, yet has character and most importantly, evokes trust in users. To achieve this, I performed a usability study, which showed me that participants needed more information on the Aid Center to establish credibility.

          To address this, I made changes to the top section of the website by adding descriptions, pictures, and more color to highlight important information. These changes proved to be helpful in orienting users on the website and building credibility and empathy."
          process={["mockups", "high-fidelity prototype", "usability study"]}
          pink
        >
          <div className="common-mocks-container">
            <h2 className="common-subtitle">Mockups</h2>
            <div className="common-content-wide">
              <WideTwoColumn
                titleLeft="Pop-up shopping cart"
                textLeft='I added a pop-up shopping cart on the website that allows users to add items and continue shopping without leaving the homepage. The cart includes a "consider adding" option to suggest other items for the user.'
                imgLeft={Box_mocks_1}
                titleRight="PICTURES TO EVOKE EMOTIONS"
                textRight="I included a carousel with pictures and videos of people affected by war on the website to help users empathize and evoke emotions, making the cause more personal and real."
                imgRight={Box_mocks_2}
              />
              <WideTwoColumn
                titleLeft="shopping cart"
                textLeft="The shopping cart allows users to easily add suggested items right before they check out. Payment options such as PayPal and auto-fill with a credit card are available to make the process of payment quick and secure. This way, users can have a smooth and seamless experience while making a purchase on the website."
                imgLeft={Box_mocks_3}
                titleRight="ukrainian colors"
                textRight="To enhance the Ukrainian character of the website, I incorporated the traditional colors of blue and yellow into the color palette. I used a saturated yellow for call-to-action buttons to make them stand out, while blue was used for section colors and accents. This helped to create a cohesive and visually pleasing design that aligns with the Ukrainian identity of the organization."
                imgRight={Box_mocks_4}
              />
            </div>
            <h2 className="common-subtitle">Usability Study Findings</h2>
            <div className="common-content-wide">
              <WideTwoColumn
                titleLeft="Before"
                textLeft="Study participants reported that they don’t feel emotionally moved enough to purchase a box of goods."
                imgLeft={Box_mocks_5}
                titleRight="After"
                textRight=" I added images at the top section of the website to evoke more emotional response."
                imgRight={Box_mocks_7}
              />
              <WideTwoColumn
                titleLeft="Before"
                textLeft="Users wanted to know more about how the package gets delivered to Ukrainian families."
                imgLeft={Box_mocks_6}
                titleRight="After"
                textRight=" I added a section below the box of goods choices that explained the package delivery process to create more credibility."
                imgRight={Box_mocks_8}
              />
            </div>
          </div>
        </MocksPortfolio>
        <AccessibilityPortfolio
          simple
          title="Accessibility"
          color="blue"
          steps={[
            "I made sure that the colors used on the website meet the WCAG requirements. The contrast ratio was set to 4.87, which is compliant with AA standards.",
            "To address temporary accessibility issues such as users accessing the website on the go, I made sure that each target UI element on the responsive website is at least 44px x 44px in size.",
            "To maintain a clear visual hierarchy and allow for easy scanning of the website, I added larger font sizes for headlines. This improves the readability and makes it easier for users to quickly find the information they are looking for.",
          ]}
        />
        <VisualDesignPortfolio
          description="My inspiration for the color palette was the colors of the Ukrainian flag - yellow and blue. These colors stand for the blue sky above the yellow field of wheat grass, which represents freedom-loving, vibrant, and brave Ukrainians. I also incorporated a pixelized folklore pattern into the visual language of the website as a nod to Ukrainian folklore art. This helped to create a cohesive design that aligns with the Ukrainian identity of the organization."
          colors={[
            { color: "#FFE565", text: "#241F1D" },
            { color: "#216DDF", text: "#FBF9F7" },
            { color: "#DBE7FA", text: "#241F1D" },
            { color: "#001322", text: "#FBF9F7" },
          ]}
          type1={Box_type_1}
          type2={Box_type_2}
          color="blue"
          label="sticker sheet"
          url={stickerSheetUrl}
        />
        <FinalDesignPortfolio
          description="The biggest lesson I learned from this project is the importance of usability testing. The first version of the website that I delivered to the client was very satisfactory, however, after conducting usability testing, participants reported not feeling moved enough by the website to support the organization. Through adding images and stronger call-to-actions, I was able to change the user experience and make it more emotional and trustworthy towards the organization. Without usability testing, the first version of the website would have been the final version, which could have resulted in fewer donations and less impact. In the first 9 months since the website was launched, over 56,000 packages were purchased and sent to Ukrainian families in need. This means that over 150,000 people received help thanks to the Box of Goods website. This outcome demonstrates the effectiveness of the website in achieving its goal of providing aid to those in need and the impact of the design and features in motivating users to make a purchase."
          img={Box_flow}
          imgMobile={Box_flow_mobile}
        />
        <ViewOtherProjects />
        <Footer />
      </div>
    </>
  );
}

export default BoxOfGoods;
