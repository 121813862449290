import "./Rectangle.css";

function Rectangle(props) {
  return (
    <div
      className={`rectangle-container ${
        props.color === "black"
          ? "rectangle-black"
          : props.color === "mint"
          ? "rectangle-mint"
          : "rectangle-gold"
      }`}
      style={{ width: `${props.width}`, height: `${props.height}` }}
    />
  );
}

export default Rectangle;
