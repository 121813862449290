import "./Work.css";
import WorkBox from "../../components/WorkBox/WorkBox";
import Button from "../../components/Button/Button";
import Rectangle from "../../components/Rectangle/Rectangle";
/* Data */
import { projects } from "../../data/data.js";

function Work(props) {
  const portfolio = projects;

  return (
    <>
      <div className="work-container">
        {portfolio.map((item) => (
          <WorkBox
            title={item.title}
            description={item.description}
            img={item.img}
            url={item.url}
          />
        ))}
      </div>{" "}
      <div className="work-cta-container">
        {/*<Button>Contact me</Button>*/}
      </div>
      <div className="work-illustration-container">
        <Rectangle width="52px" height="100px" color="black" />
      </div>
    </>
  );
}

export default Work;
