import "./WorkBox.css";
import { Link } from "react-router-dom";

function WorkBox(props) {
  return (
    <Link
      className="work-box-container work-box-container-simple"
      style={{ background: `url(${props.img})` }}
      to={`/${props.url}`}
    >
      <h3 className="work-box-title">{props.title}</h3>
    </Link>
  );
}

export default WorkBox;
