import "./TrustedTwinAcademy.css";
/* Components */
import WideTwoColumn from "../../components/WideTwoColumn/WideTwoColumn";
/* Containers */
import Nav from "../../containers/Nav/Nav";
import Footer from "../../containers/Footer/Footer";
import HeroPortfolio from "../../containers/HeroPortfolio/HeroPortfolio";
import OverviewPortfolio from "../../containers/OverviewPortfolio/OverviewPortfolio";
import ResearchPortfolio from "../../containers/ResearchPortfolio/ResearchPortfolio";
import MocksPortfolio from "../../containers/MocksPortfolio/MocksPortfolio";
import AccessibilityPortfolio from "../../containers/AccessibilityPortfolio/AccessibilityPortfolio";
import VisualDesignPortfolio from "../../containers/VisualDesignPortfolio/VisualDesignPortfolio";
import FinalDesignPortfolio from "../../containers/FinalDesignPortfolio/FinalDesignPortfolio";
import ViewOtherProjects from "../../containers/ViewOtherProjects/ViewOtherProjects";
/* Assets */
import Academy_1 from "../../assets/img/tt_academy/tt_academy1.png";
import Academy_2 from "../../assets/img/tt_academy/tt_academy2.png";
import Academy_3 from "../../assets/img/tt_academy/tt_academy3.png";
import Academy_4 from "../../assets/img/tt_academy/tt_academy4.png";
import Academy_5 from "../../assets/img/tt_academy/tt_academy5.png";
import Academy_6 from "../../assets/img/tt_academy/tt_academy6.png";
import Academy_7 from "../../assets/img/tt_academy/tt_academy7.png";
import TT_persona from "../../assets/img/tt_academy/tt_persona.png";
import Academy_flow from "../../assets/img/tt_academy/tt_academy_flow.png";
import Academy_flow_mobile from "../../assets/img/tt_academy/tt_academy_flow_mobile.png";

function TrustedTwinAcademy() {
  return (
    <>
      <Nav hideElements pink />
      <div className="box-container commmon-portfolio-container">
        <HeroPortfolio
          title="TRUSTED TWIN ACADEMY"
          description="Website design for Academy/ tutorial page for a digital twin Polish start-up."
          url="http://TRUSTEDTWIN.COM/ACADEMY"
          label="TRUSTEDTWIN.COM/ACADEMY"
          img={Academy_1}
          beforeHeight="500"
          color="red-dark"
          imgKind="web"
        />
        <OverviewPortfolio
          title="overview"
          role="ux design lead"
          duration="NOVEMBER - DECEMBER 2022"
          client="Trusted Twin"
          product="Trusted Twin is a start-up based in Poland. It is API-first solution for sharing operational data with multiple partners powered by Digital Twins. The Academy is a place for new users, where they can find out how to navigate throught the Trusted Twin platform."
          goal="The goal was to design an Academy page for Trusted Twin website to guide users through tutorials on using the platform, with easy navigation."
          challenge="Creating a user-friendly navigation for the Academy page was a key challenge in this project. The goal was to guide users through the tutorials and make it easy for them to switch between articles."
        />
        <ResearchPortfolio
          process={["PERSONAS", "USER STORIES", "COMPETETIVE AUDIT"]}
          processDescription="After defining the problem and goals for the project, I moved onto sketching paper wireframes and then transferring them into digital wireframes in Figma. I also created a low-fidelity prototype to get initial feedback from the client. 
          The main focus was on creating an easy navigation flow for users to guide them through the tutorials and articles. The competitive audit helped me understand industry standards and common patterns for documenting tutorials, which informed my design choices."
          problemStatement="Jan is a developer, who recently joined the Trusted Twin platform. He is struggling to understand all the concepts of the platform so he needs to educate himself through tutorials."
          personaImage={TT_persona}
          personaQuote="“I want to learn more how to use the Trusted Twin platform.”"
          name="JAN"
          age="42"
          education="Master degree"
          hometown="Warsaw, Poland"
          family="single"
          occupation="developer"
          goals={[
            "Have access to the articles explaining how to navigate the Trusted Twin platform",
          ]}
          frustrations={[
            "The lack of resources explaining how to use the platform",
          ]}
          painpointsDescription="As a developer new to Trusted Twin I want to have access to articles on how to use the platform so that I can have a better understanding on how I can benefit from the product."
          competitiveAudit="I used the research to create low-fidelity wireframes for the Academy page, focusing on creating an easy navigation flow for users to find and access the tutorials they need. I also ensured that the tutorials were easily searchable and organized by topic to make it easy for users to find what they need. The final design was developed into a high-fidelity prototype and reviewed by the Trusted Twin team for feedback before implementation."
        />
        <MocksPortfolio
          processTitle="mid fidelity"
          process={["MID-FIDELITY DIGITAL WIREFRAMES", "USABILITY STUDY"]}
          processDescription="After researching competitors and consulting with the client to understand their needs I moved on to creating mid-fidelity wireframes. 
          The diagram of the platform was an essential part of the design. By placing it at the central part of the page I ensured that users can have a good visual understanding of where to find information on each part of the platform. "
        >
          <div className="common-container">
            <h2 className="common-subtitle">MID FIDELITY MOCKUPS</h2>
            <WideTwoColumn
              titleLeft="MAIN PAGE"
              textLeft="The diagram is at the center part of the page for easy visual understanding. Highlighted articles help users identify essential content."
              imgLeft={Academy_2}
              titleRight="ARTICLE PAGE"
              textRight="The navigation on the right side of the page makes it easy for users to find the information they need and quickly jump to different sections of the article."
              imgRight={Academy_3}
            />
          </div>
        </MocksPortfolio>
        <MocksPortfolio
          processTitle="high fidelity"
          processDescription="After iterating on the mid-fidelity mockups, I moved on to creating the high-fidelity design. I made improvements to the navigation on article pages and made changes in visual hierarchy of components.
          I made sure to pay attention to details such as typography and color palette to make the design look polished and professional. I also made sure to keep the design consistent with the overall Trusted Twin website design. Overall, the goal was to create a clear and easy to navigate Academy page that guides users through the tutorials and articles."
          process={["mockups", "high-fidelity prototype", "usability study"]}
          pink
        >
          <div className="common-mocks-container">
            <h2 className="common-subtitle">HIGH FIDELITY MOCKUPS</h2>
            <div className="common-content-wide">
              <WideTwoColumn
                titleLeft="Visual Hierarchy — Before"
                textLeft="The developer resources callout component was visually too strong and drew users' attention out of their main task — finishing reading the article."
                imgLeft={Academy_4}
                titleRight="Visual Hierarchy — After"
                textRight="I simplified the developer resources component so that it is still highlighted and stands out from the rest of the content, but doesn’t distract users from finishing the article."
                imgRight={Academy_6}
              />
              <WideTwoColumn
                titleLeft="Supplemental Navigation — Before"
                textLeft="In the first iteration of the project I designed a large component listing related articles. After running a usability study and consulting with the client it became obvious that the continuation of the user journey should be focused on a linear transition to the next article rather than jumping to related articles."
                imgLeft={Academy_5}
                titleRight="Supplemental Navigation — After"
                textRight="To better serve user primary objective, I moved the supplamental navigation above the resources section and de-prioritized it visually. The new design performed focused users' attention on previous/next navigation. This approach led to a significantly better performance and was more aligned with the desired user journey."
                imgRight={Academy_7}
              />
            </div>
          </div>
        </MocksPortfolio>
        <FinalDesignPortfolio
          description="During the Trusted Twin Academy project, I had the opportunity to dive deep into the world of tutorial page design. I consulted with the client to understand their needs and expectations, and conducted a competitive audit to see how other companies approached documenting their tutorials. I also learned about the importance of navigation and how to simplify it to guide users through the content. 
          Overall, this project was a valuable learning experience for me and I feel more confident in my ability to design effective tutorial pages in the future."
          img={Academy_flow}
          imgMobile={Academy_flow_mobile}
        />
        <ViewOtherProjects />
        <Footer />
      </div>
    </>
  );
}

export default TrustedTwinAcademy;
