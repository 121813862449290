import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import "./Nav.css";
import Logo from "../../components/Logo/Logo";
import NavLink from "../../components/NavLink/NavLink";
import Lines from "../../components/Lines/Lines";
import Rectangle from "../../components/Rectangle/Rectangle";

// Assets
import hamburger from "../../assets/vectors/hamburger.svg";
import close from "../../assets/vectors/close.svg";

function Nav(props) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  /* Scroll for nav */
  const handleScroll = () => {
    // find current scroll position
    const currentScrollPos = window.pageYOffset;

    // set state based on location info (explained in more detail below)
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
      currentScrollPos < 220
    );

    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  };

  // new useEffect:
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  // Smooth scroll for nav links
  useEffect(() => {
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  // Change path on scroll to the top of the page

  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentURL, setCurrentURL] = useState();
  const navigate = useNavigate();

  const handleScroll2 = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  // test nav
  const isBetween = (value, floor, ceil) => value >= floor && value <= ceil;
  const clamp = (value) => Math.max(0, value);

  // hooks
  const useScrollspy = (ids, offset = 0) => {
    const [activeId, setActiveId] = useState("");

    useLayoutEffect(() => {
      const listener = () => {
        const scroll = window.pageYOffset;

        const position = ids
          .map((id) => {
            const element = document.getElementById(id);

            if (!element) return { id, top: -1, bottom: -1 };

            const rect = element.getBoundingClientRect();
            const top = clamp(rect.top + scroll - offset);
            const bottom = clamp(rect.bottom + scroll - offset);

            return { id, top, bottom };
          })
          .find(({ top, bottom }) => isBetween(scroll, top, bottom));

        setActiveId(position?.id || "");
      };

      listener();

      window.addEventListener("resize", listener);
      window.addEventListener("scroll", listener);

      return () => {
        window.removeEventListener("resize", listener);
        window.removeEventListener("scroll", listener);
      };
    }, [ids, offset]);

    return activeId;
  };

  const ids = ["work", "about", "contact"];
  const activeId = useScrollspy(ids, 99);

  // TODO clean up nav code

  /* Hiding nav for mobile */

  const [navVisible, setNavVisibility] = useState(false);

  /* Hiding stylistic elements for portfolio pages.
  ** if prop hideElements is true, hide elements responsible for 
  ** stylistic elements that are only needed on the homepage.
  */

  return (
    <nav className="nav-container" style={{ backgroundColor: `${props.pink === true ? "#FBF9F7" : "white"}` }}>
      <div className="logo-lines-container">
        <Logo
          onClick={() => {
            let home = document.getElementById("home");
            home &&
              home.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        />
        <div
          className={`logo-lines-container ${!visible ? "logo-lines-container-invisible" : ""
            }`}
        >
          {props.hideElements ? null : <Lines />}
        </div>
        {props.hideElements ? null : (
          <div
            className={`logo-lines-short-rectangle-container ${!visible ? "logo-lines-short-rectangle-container-invisible" : ""
              }`}
          >
            <Rectangle color="mint" width="52px" height="36px" />
          </div>)}
      </div>
      {props.hideElements ? <Link to="/work"><img src={close} style={{filter: "invert(100%)"}} /></Link> : (
        <ul className={`nav-links-container ${navVisible ? "nav-links-container-show" : "nav-links-container-hide"}`}>
          <NavLink
            active={activeId === "work" ? true : false}
            onClick={() => {
              setNavVisibility(!navVisible);
              let about = document.getElementById("work");
              about &&
                about.scrollIntoView({ behavior: "smooth", block: "start" });
            }}
          >
            Work
          </NavLink>
          <NavLink
            active={activeId === "about" ? true : false}
            onClick={() => {
              setNavVisibility(!navVisible);
              let about = document.getElementById("about");
              about &&
                about.scrollIntoView({ behavior: "smooth", block: "start" });
            }}
          >
            About
          </NavLink>
          <NavLink
            active={activeId === "contact" ? true : false}
            onClick={() => {
              setNavVisibility(!navVisible);
              setCurrentURL("/contact");
              let about = document.getElementById("contact");
              about &&
                about.scrollIntoView({ behavior: "smooth", block: "start" });
            }}
          >
            Contact
          </NavLink>
        </ul>)}
      {/* Nav button for mobile */}
      {props.hideElements ? null : (
      <button className="nav-button-container" onClick={() => {
        navVisible ? setNavVisibility(false) : setNavVisibility(true)
      }}>
        {navVisible ? (<img src={close} alt="close" />) : (<img src={hamburger} alt="hamburger" />)}
      </button>)}
    </nav>
  );
}

export default Nav;
