import "./OverviewPortfolio.css";
import Dot from "../../components/Dot/Dot";

function OverviewPortfolio(props) {
    return (
        <div className="overview-portfolio-container">
            <div className="overview-portfolio-content">
                <h1 className="overview-portfolio-title">{props.title} <Dot color="black" /> </h1>
                <ul className="overview-portfolio-shortlist">
                    <li className="overview-portfolio-item">
                        <h2 className="overview-portfolio-item-title">My role:</h2>
                        <p className="overview-portfolio-item-description">{props.role}</p>
                    </li>
                    <li className="overview-portfolio-item">
                        <h2 className="overview-portfolio-item-title">Duration:</h2>
                        <p className="overview-portfolio-item-description">{props.duration}</p>
                    </li>
                    <li className="overview-portfolio-item">
                        <h2 className="overview-portfolio-item-title">Client:</h2>
                        <p className="overview-portfolio-item-description">{props.client}</p>
                    </li>
                </ul>
                <ul className="overview-portfolio-details">
                    <li className="overview-portfolio-details-item">
                        <h2 className="overview-portfolio-details-title">Product</h2>
                        <p className="overview-portfolio-details-description">{props.product}</p>
                    </li>
                    <li className="overview-portfolio-details-item">
                        <h2 className="overview-portfolio-details-title">Goal</h2>
                        <p className="overview-portfolio-details-description">{props.goal}</p>
                    </li>
                    <li className="overview-portfolio-details-item">
                        <h2 className="overview-portfolio-details-title">Challenges</h2>
                        <p className="overview-portfolio-details-description">{props.challenge}</p>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default OverviewPortfolio;
